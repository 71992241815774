var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"materialValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('br'),_c('h2',{staticClass:"shipment_create_header"},[_vm._v("Material cost")]),_c('div',{staticClass:"pt-3"},[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.materialcosts),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,staticClass:"pt-2",attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Description*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Quantity*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"quantity","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter quantity"},on:{"input":function($event){return _vm.changeAmount(item.unitprice, item.quantity, index)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Unit Price*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"unit price","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Unit Price","type":"number","step":"0.00"},on:{"input":function($event){return _vm.changeAmount(item.unitprice, item.quantity, index)}},model:{value:(item.unitprice),callback:function ($$v) {_vm.$set(item, "unitprice", $$v)},expression:"item.unitprice"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Amount*","label-class":"form_label_class"}},[_c('b-form-input',{attrs:{"readonly":"","placeholder":"Enter amount","type":"number","step":"0.00"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1)],1),_c('b-col',{staticClass:"minus_button_margin",attrs:{"lg":"1"}},[_c('b-button',{attrs:{"variant":"none"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('b-img',{attrs:{"src":require("@/assets/images/Group.png")}})],1)],1)],1)}),1)],1),_c('br'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"form_submit_button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.repeateAgain1()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Add cost")])])],1)]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"pt-3"}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-button',{staticClass:"backbutton",attrs:{"variant":"none"},on:{"click":function($event){return _vm.back()}}},[_c('span',{staticClass:"back_button_text_styles"},[_vm._v("Back")])])],1),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"form_submit_button",attrs:{"variant":"none","disabled":invalid}},[_c('span',{staticClass:"button_text_styles",on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }